<script setup lang="ts">

import { useFavouritesStore } from '~/store/favourites';

const route=useRoute();
const { logout,user} = useSanctumAuth();
const brand=ref(route.params.marca); 

const breadcrums = [
    {
        route: '/panel',
        name: 'Panel de usuario',
        active: true
    }
];

const props=defineProps({
  selected: {
    type: Number,
    default: 0,
  }
});

const layoutProps = computed(() => {
  return brand.value ? { name: 'brand' } : {};
});

const logoutUser = async ()=> {
  return await logout();
};

</script>

<template lang="pug">
NuxtLayout(:="layoutProps")
  .app-panel(:class="{'pb-4':brand}")
    .container.py-3.py-lg-5
      .row
        .col-12
          UiAppBreadcrumbs(:class="{'adjustBrandsBreadcrumbs':brand}" :items="breadcrums" :initialRoute="brand ? `/${brand}`: '/'")
    .container
      .app-panel__user-info.d-flex.flex-row.align-items-center
          //-img(v-if="user?.avatar && user?.avatar!=''" :src="user?.avatar")
          .app-panel__user-info--text
            p Bienvenido a tu área privada 
            p {{ user?.nombre ?? '' }} {{user?.apellidos ?? ''}} 
      .container
        .grid--18.mt-5
          .grid-child--1-end.grid-child-lg--1-6
            section
              article
                .container
                  .row
                    .app-panel__options
                      h2 Mi menú
                      ul
                        li
                          NuxtLink(:to="brand ? `/${brand}/panel/favoritos/`: '/panel/favoritos/'" :class="{'text-danger':selected===1}")
                            span.icon-favoritos
                            p Mis favoritos
                            span.after.icon-arrow-circle-down

                        li
                          NuxtLink(:to="brand ? `/${brand}/panel/busquedas/`: '/panel/busquedas/'" :class="{'text-danger':selected===2}")
                            span.icon-busquedas 
                            p Búsquedas guardadas
                            span.after.icon-arrow-circle-down

                        li
                          NuxtLink(:to="brand ? `/${brand}/panel/reservas/`: '/panel/reservas/'" :class="{'text-danger':selected===3}")
                            span.icon-reservas 
                            p Reservas
                            span.after.icon-arrow-circle-down

                        li
                          NuxtLink(:to="brand ? `/${brand}/panel/comparador/`: '/panel/comparador/'" :class="{'text-danger':selected===4}")
                            span.icon-comparador
                            p Comparador
                            span.after.icon-arrow-circle-down

                        //-li 
                          NuxtLink(to="/panel/favoritos" :class="{'text-danger':selected===5}")
                            span.icon-tasacion 
                            p Vehículos tasados
                            span.after.icon-arrow-circle-down

                        //-li 
                          NuxtLink(to="/panel/favoritos" :class="{'text-danger':selected===6}")
                            span.icon-alertas
                            p Alertas
                            span.after.icon-arrow-circle-down

              article
                .container
                  .row.mt-5
                    .app-panel__options
                      h2 Mi perfil
                      ul
                        li 
                          NuxtLink(:to="brand ? `/${brand}/panel/perfil/`: '/panel/perfil/'" :class="{'text-danger':selected===7}")
                            span.icon-user-square
                            p Mi perfil
                            span.after.icon-arrow-circle-down

                        //-li
                          NuxtLink(to="/panel/favoritos" :class="{'text-danger':selected===8}")
                            span.icon-mensajes 
                            p ¿Necesitas ayuda?
                            span.after.icon-arrow-circle-down

              span.logout.my-5(@click="logoutUser()") Cerrar sesión
          .grid-child--1-end.grid-child-lg--6-end.grid-child-lg--row-1-end
            section.h-100
              .container.h-100.px-lg-5.py-5
                slot

      
</template>

<style scoped lang="scss">
.app-panel{
  background-color: var(--neutral-40);

  &__user-info{
    img{
      border-radius: 50%;
      width: 77px;
      height: 77px;
      
    }

    &--text{
      margin-left: 25px;
      padding-top: 9px;
      p{
        &:first-child{
          font-size: 14px;
        }

        &:last-child{
          font-size: 24px;
          line-height: 30px;

        }
      }
    }
  }

  &__options{
    h2{
      font-size: 28px;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 2%;
    }
    ul{
      border-radius: 30px;
      padding: 6% 10%;
      background-color: var(--white);
      li{
        position: relative;
        font-size: 16px;
        span{
          position: absolute;
          top: 50%;
          left: 0%;
          transform: translate(-50%, -50%);
          font-size: 24px;
          &.after{
            left: 100%;
          }

          &.icon-arrow-circle-down{
            color: red;
            font-size: 17px;
          }
        }
        p{
          margin: 10%;
        }

        /*&::after{
          content:'\e96f';
          font-family: icomoon;
          color: red;
          position: absolute;
          top: 50%;
          right: -5%;
          transform: translate(-50%, -50%);
        }*/
      }
    }
  }


  .logout{
    padding-left: 10%;
    //padding-right: 30px;
    font-size: 15px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    border: none;
    cursor: pointer;
    &::after{
      content:'\e9e7';
      font-family: icomoon;
      font-size: 15px;
      position: absolute;
      top: 53%;
      right: -30px;
      transform: translate(-50%, -50%);
    }
  }

  @media (min-width:$breakpoint_lg) {
    &__user-info{
      &--text{
        line-height: 20px;
      p{
        &:last-child{
          font-size: 36px;
        }
      }
    }
    }

    &__options{
      ul{
        li{
          font-size: 21px;
        }
      }
    }
  }
}
</style>